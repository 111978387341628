@import "../../../styles/global";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.button {
    @include cta;
}

@media (min-width: 760px) {

}