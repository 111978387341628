@import "../../../styles/global";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
    margin: 40px 40px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 760px) {
    .container {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .column-1 {
        flex: 0 0 50%;
    }
    .column-2 {
        flex: 0 0 50%;
    }
}

.image {
    width: 100%;
}

.title {
   @include title;
   margin: 40px 0px;
}

.price {
    @include price;
    margin-bottom: 40px;
}

.description {
    @include description;
    margin-bottom: 40px;
}

.size-heading {
    font-size: $font-sm;
    color: $color-text-light;
    font-weight: $weight-bold;
    margin-bottom: 16px;
}

.required {
    color: $color-text-required;
}

.letter {
    display: inline-flex;
    padding-left: 10px;
    color: $color-text-dark;
}

.size-container {
    display: flex;
    gap: 8px
}

.sizes {
    color: $color-text-light;
    border: 0.5px solid $color-text-light;
    padding: 16px 20px;
    text-align: center;
    cursor: pointer;
    &--selected {
        border: 2px solid black;
    }
}

.button {
    @include cta;
    margin: 40px;
}

.error {
    color: $color-text-required;
}