@import "./variables";

@mixin title {
    color: $color-text-dark;
    font-size: $font-xl;
}

@mixin price {
    color: $color-text-dark;
    font-size: $font-reg;
    font-weight: $weight-bold;
}

@mixin description {
    color: $color-text-light;
    font-size: $font-reg;
    font-weight: $weight-light;
    line-height: 1.5;
}

@mixin cta {
    margin: 20px 0px;
    padding: 12px 24px;
    border: 2px solid $color-text-dark;
    background-color: transparent;
    font-size: $font-xl;
    font-weight: $weight-bold;
    transition: 0.2s ease-in-out;
    &:hover {
        color: white;
        background-color: $color-text-dark;
        cursor: pointer; 
    }
    &--disabled {
        color: grey;
        border: 2px solid grey;
        cursor: initial;
        &:hover {
            color: grey;
            background-color: transparent;
            cursor: initial;
        }
    }
}

@mixin count {
    color: $color-text-light;
    font-size: $font-lg;
    font-weight: $weight-light;
    align-items: center;
}