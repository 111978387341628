$color-header-bg: #F6F6F7;
$color-text-dark: #222222;
$color-text-light: #888888;
$color-text-required: #C90000;
$color-border-dark: #222222;
$color-border-light: #CCCCCC;

$font-sm: 10px;
$font-reg: 12px;
$font-lg: 14px;
$font-xl: 18px;

$weight-light: 100;
$weight-bold: 700;