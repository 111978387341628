@import "../../../styles/global";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
    margin: 40px 0px;
    padding: 20px 0px;
    background-color: $color-header-bg;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.cart {
    display: flex;
    cursor: pointer;
}

.my-cart {
    display: none;
    color: #888888
}

.count {
    @include count;
    margin-left: 12px;
    padding-right: 24px;
    align-self: center;
}

@media (min-width: 760px) {
    .icon {
        display: none;
    }

    .my-cart {
        display: block;
    }
}